/* eslint-disable default-case */
import React, { Component } from 'react';
import { Layout, message,Table, Button } from 'antd';
import intl from 'react-intl-universal';
import { Link } from "react-router-dom";
import './List.scss';
import Cookies from 'universal-cookie';
import { inject, observer } from 'mobx-react';

const cookies = new Cookies();

const { Header, Content, } = Layout;
@inject('store')
@observer
class Reports extends Component {
  constructor(props){
    super();
    this.state = {
      domain : global.Api.domain_url(),
      lang : intl.options.currentLocale,
      querystr: "",
      list:[],
      listColumns:[
        {title:intl.get('number'),dataIndex:'id'},
        {title:intl.get('device_number'),dataIndex:'device_number'},
        {title:intl.get('device_time'),dataIndex:'device_time',
         render: (device_time) => (
          <span>{device_time.replace('T',' ')}</span>
          )
        },
        {title:intl.get('wear_co'),
          render: ({wear_lf_degree,wear_rf_degree,wear_lb_degree,wear_rb_degree}) => {
            let _wear = [wear_lf_degree,wear_rf_degree,wear_lb_degree,wear_rb_degree]
            return (
              <>
                {_wear.map((item,index)=>(
                  <span className="point" key={index} style={{ background: this.wheelStatusColor(item)}}></span>
                ))}
              </>
            ) 
          }
        },
        {title:intl.get('ecc_co'),
         render: ({ecc_wear_lf_degree,ecc_wear_rf_degree,ecc_wear_lb_degree,ecc_wear_rb_degree}) => {
            let _wear = [ecc_wear_lf_degree,ecc_wear_rf_degree,ecc_wear_lb_degree,ecc_wear_rb_degree]
            return (
              <>
                {_wear.map((item,index)=>(
                  <span className="point" key={index} style={{ background: this.wheelStatusColor(item)}}></span>
                ))}
              </>
            ) 
          }
        },
        {title:intl.get('plate_no'),dataIndex:'plate_no',
        render: (plate_no) => (
            <span>{plate_no.toUpperCase()}</span>
          )},
        {title:intl.get('cus_ph'),dataIndex:'mobile',
          render: (mobile) => (
            <span>{mobile ? mobile : '-'}</span>
          )
        },
        {title:intl.get('car_type'),dataIndex:'car',
          render: (car) => (
            <span>{car ? car : '-'}</span>
          )
        },
        {title:intl.get('operation'),key:'actions',
          render: ({id,uuid}) => (
            <div style={{display: "flex"}}>
              {/* <Link to={`/detail/${id}`}>{intl.get('view')}</Link> */}            
              <a href="#" onClick={()=>this.openReport(uuid)}>{intl.get('view')}</a>
            </div>
          ),
        },
      ],
      pagination: {},
      loading: false,
      rep_time: "",
    }
  }

  componentDidMount(){
    if (this.state.lang === 'ge'){
      this.setState({
          lang:'de'
      })
    }else if (this.state.lang === 'sp'){
        this.setState({
            lang:'es'
        })
    }else if (this.state.lang === 'ma'){
      this.setState({
          lang:'ms'
      })
    }else if (this.state.lang === 'lt'){
      this.setState({
          lang:'it'
      })
    }else if (this.state.lang === 'tw'){
      this.setState({
          lang:'zh_TW'
      })
    }else if (this.state.lang === 'jp'){
      this.setState({
          lang:'ja'
      })
    }else if (this.state.lang === 'po'){
      this.setState({
          lang:'pl'
      })
    }else if (this.state.lang === 'por'){
      this.setState({
          lang:'pt'
      })
    }else if (this.state.lang === 'dk'){
      this.setState({
          lang:'da'
      })
    }

    this.getReports()
  }

  openReport(uuid){
    if (this.state.domain ==='iptirescanner.com'){
      // window.location.href = `http://customer.${this.state.domain}/report/${uuid}/?lang=${intl.options.currentLocale}`;
      window.open(`http://customer.${this.state.domain}/report/${uuid}/?lang=${this.state.lang}`);
    }
    else
      // window.location.href = `https://customer.${this.state.domain}/report/${uuid}/?lang=${intl.options.currentLocale}`;
      window.open(`https://customer.${this.state.domain}/report/${uuid}/?lang=${this.state.lang}`);
  }

  // 获取列表
  getReports(params = {page : 1,device_time:this.rep_time}){
    this.setState({ loading: true });
    global.Api.getReports({
      qs:'',
      ...params
    }).then(res=>{
      if (res.data.code === 0) {
        const pagination = { ...this.state.pagination };
        let _data = res.data.data;
        pagination.total = _data.total;
        pagination.pageSize = 15;
        this.setState({
          loading: false,
          list: _data.reports,
          pagination,
        });
        return
      }
      message.error(res.data.message);      
    })
  }
  //下载报告
  reportsDownload = () => {
    global.Api.downloadReports({device_time:this.rep_time, lang:intl.options.currentLocale}).then(res =>{
      if (res.data.code === 0){
        let _data = res.data.data;
        window.location.href = _data.download_url;
      }
    })
  }

  //搜索
  inputChange(e){
    console.log(e.target.value)
    this.setState({
      querystr:e.target.value
    })
  }
  getInputValue(){
    console.log(this.state.pagination);
    if(!this.state.pagination.current){
      this.getReports({
        page: 1,
        search: this.state.querystr,
        per_page: this.state.pagination.pageSize,
        device_time:this.rep_time
      });
    }else{
      this.getReports({
        page: this.state.pagination.current,
        search: this.state.querystr,
        per_page: this.state.pagination.pageSize,
        device_time:this.rep_time
      });
    }
  }
  // 翻页
  handleTableChange = (pagination) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({
      pagination: pager,
    });
    this.getReports({
      page: pagination.current,
      per_page: pagination.pageSize,
      device_time:this.rep_time
    });
  }

  // 根据轮胎状态返回颜色
  wheelStatusColor(status) {
    switch (status) {
      case 'low':
        return '#0c0';
      case 'middle':
        return '#f90';
      case 'high':
        return '#ff3300';
    }
  }

  //筛选
  select = () =>{
    let select = document.getElementById('TimeId');
    this.rep_time = select.value
    console.log(this.rep_time);
    this.getReports()

  }

  render() {
     // 选择器
   const  TimeSelect = () =>{
      return(
        <select id="TimeId"  onChange={() => this.select()}  style={{color:"blue"}}>
            <option value='' >-{intl.get('time')}-</option>
            <option value="">All</option>
            <option value="today">{intl.get('today')}</option>
            <option value="yesterday">{intl.get('yesterday')}</option>
            <option value="week">{intl.get('week')}</option>
            <option value="month">{intl.get('month')}</option>
            <option value="last_month">{intl.get('last_month')}</option>
            <option value="year">{intl.get('year')}</option>
        </select>
      )
  
   }
    return (
      <Layout className="reports">
        <Header style={{position: 'fixed', zIndex: 1, width: '100%',height:'120px',backgroundColor:'#3385ff',color:"#fff",}}>
          <div style={{marginRight:"20px",color:"#fff"}} >
            <span style={{fontSize:"20px",color:"#fff"}}>Handy Tyre Scanner</span>
          {/* <img class="logo img-fluid" src="https://mobilhubdiagnostics.com/img/logo-1667829529.jpg" alt='' width="228" height="38" /> */}
          </div>
          <Button type="link" style={{marginLeft:"0px",marginRight:"20px",color:"#fff",fontSize:'15px',fontWeight:'500',}} ><Link to="/dashboarddealer">{intl.get('dashboard')}</Link></Button>
          
          <TimeSelect />
          <Button type="link" style={{marginLeft:"0px",color:"#fff"}} >
            <a href="#" onClick={this.reportsDownload} style={{marginLeft:"10px",fontSize:'15px',fontWeight:'500',}}>{intl.get('download')}</a>
          </Button>
          <Button type="link" style={{marginLeft:"10px",color:"#fff",fontSize:'15px',fontWeight:'500',}} ><Link to="/dealerlist">{intl.get('reports')}</Link></Button>
          {/* <Button type="link" style={{marginLeft:"10px",color:"#fff",fontSize:'15px',fontWeight:'500',}} ><Link to="/devices">{intl.get('devices')}</Link></Button> */}
          <Button type="link" style={{marginLeft:"10px",color:"#fff",fontSize:'15px',fontWeight:'500',}} ><Link to="/storelist">{intl.get('storemanagement')}</Link></Button>
          
          <Button type="link" style={{marginRight:"10px",color:"#fff",fontSize:'15px',fontWeight:'500',}} ><Link to="/settingdealer">{intl.get('setting')}</Link></Button>
          {/* {(this.props.store.user_type === 2 || this.props.store.user_type === 3) && (<Button type="link" style={{marginRight:"10px",color:"#fff",fontSize:'15px',fontWeight:'500',}} ><Link to="/setting">{intl.get('setting')}</Link></Button>
          )} */}
          {/* <span style={{marginRight:"10px",fontSize:"10px",color:"#fff"}}>{this.props.store.user['name']}</span> */}
        </Header>
        <Content style={{ padding: '0 50px', marginTop:'110px'}}>
          <div style={{ background: '#fff', padding: 24}}>
            <input style={{height:'41px',width: '600px',borderRadius:'10px 10px 10px 10px',borderColor:'#4e6ef2'}} placeholder={intl.get('search_placeholder')} onChange={(e)=>this.inputChange(e)}/>
            <button style={{marginLeft: '-100px',padding: '2px 12px',border:'none',fontColor:'#fff',fontSize:'18px',fontWeight:'500',height:'40px',width:'100px',borderRadius:'10px 10px 10px 10px',backgroundColor:'#4e6ef2'}} onClick={() => this.getInputValue()}>{intl.get('search_btn')}</button>
            <Table 
            rowKey={item => item.id}
            dataSource={this.state.list} 
            columns={this.state.listColumns} 
            pagination={this.state.pagination} 
            loading={this.state.loading} 
            onChange={this.handleTableChange}/>
          </div>
        </Content>
      </Layout>
    );
  }
}

export default Reports;
