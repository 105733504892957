export default {
    'login': 'Login',
    'input_name': 'Please enter your user name',
    'input_password': 'Please enter your password',
    'login_su': 'Login Success',
    'role': 'role',
    'username': 'Username',
    'password': 'Password',

    'store': 'Store',
    'addstore': 'Add a new store',
    'storemanagement':'Store Management',
    'number': 'Number',
    'search_btn': 'Search',
    'device_number': 'Device Serial No',
    'device_model_number': 'Device Model Number',
    'device_model_name': 'Device Model Name',
    'search_placeholder': 'Please input device no./plate no./customer mobile',
    'device_time': 'Detection Time',
    'wear_co': 'Wear Condition (left front - right front - left back - right back)',
    'ecc_co': 'Excessive Condition (left front - right front - left back - right back)',
    'plate_no': 'License Plate',
    'cus_ph': 'Contact',
    'car_type': 'Car Type',
    'operation': 'Operation',
    'view': 'View Details',
    'print': 'Print(check background image)',
    'store_id': 'Store ID',
    'store_name': 'Store Name',
    'store_email': 'Store Email',
    'store_contact': 'Store Contact',
    'address': 'Store Address',
    'model': 'Vehicle Model',
    'mileage': 'Mileage',
    'device': 'Device',
    'remark': 'Remarks',
    'purpose': 'Purpose',
    're': 'Recommendations',
    'color_flag': 'Color scheme',
    'wear': 'Wears',
    'brake': 'Brake Distance Reminder',
    'ecc': 'Uneven',
    'lf': 'L-F',
    'lr': 'L-R',
    'rf': 'R-F',
    'rr': 'R-R',
    'untest': 'Tire not tested',
    'low': 'Normal',
    'middle': 'Boundary',
    'high': ' Replace Immediately',
    'avg': 'Average',
    'spec': "SPEC",
    'brand': 'Brand',
    'ecc_avg': 'Unifrom Wear',
    'trauma': 'Tire Trauma',
    'zc': 'Normal',
    'image': 'en',
    'qr': 'Scan To Report',
    'model_number': 'HH Tire Tread Depth Detector',
    'purp_wash': 'Car Wash & Detailing',
    'purp_alignment': 'Alignment',
    'purp_replace': 'Tire replacement',
    'purp_maintain': 'Maintenance',
    'warn': 'Remind:According to the tire wear, the braking distance is increased by',
    'trauma_normal': 'Normal',
    'trauma_bulge': 'Bulge',
    'trauma_aging': 'Aging',
    'manufacturer':'Vehicle Make',
    'porduced_year':'Vehicle Year',
    'damage':'Damage Photos',
    'dill-re':'Inspection Report',

    'txt_lf_outworn': 'The outside of Left Front tire is worn than inner',
    'txt_lf_innerworn': 'The inner of Left Front tire is worn than outside',
    'txt_lf_bothsideworn': 'Both sides of Left Front tire is worn than centre',
    'txt_lf_centreworn': 'Centre of Left Front tire is worn than both sides',
    'txt_lb_outworn': 'The outside of Left Rear tire is worn than inner',
    'txt_lb_innerworn': 'The inner of Left Rear tire is worn than outside',
    'txt_lb_bothsideworn': 'Both sides of Left Rear tire is worn than centre',
    'txt_lb_centreworn': 'Centre of Left Rear tire is worn than both sides',
    'txt_rb_outworn': 'The outside of Right Rear is worn than inner',
    'txt_rb_innerworn': 'The inner of Right Rear is worn than outside',
    'txt_rb_bothsideworn': 'Both sides of Right Rear is worn than centre',
    'txt_rb_centreworn': 'Centre of Right Rear is worn than both sides',
    'txt_rf_outworn': 'The outside of Right Front tire is worn than inner',
    'txt_rf_innerworn': 'The inner of Right Front tire is worn than outside',
    'txt_rf_bothsideworn': 'Both sides of Right Front tire is worn than centre',
    'txt_rf_centreworn': 'Centre of Right Front tire is worn than both sides',
    'txt_ecc_abnormal': 'Abnormal tire Excessive wear',

    'txt_tire_need_replace': 'Tire wear status: Replace immediately',
    'txt_tire_boundary': 'Tire wear status: Boundary',
    'txt_alignment_chassis': 'Alignment and chassis check is recommended',
    'txt_wear_uniform': 'Tire wear is uniform',
    'txt_do_further_check': 'Please do further check',
    'txt_uneven_check': 'Tire has uneven wear, Please do further check',
    'txt_tire_rotation': 'TyrehubSuggest for tire rotation',
    'txt_wear_normal': 'Tire wear is normal',
    'txt_tire': 'Tire',

    'time': 'from to',
    'today': 'Today',
    'yesterday': 'Yesterday',
    'week': 'Week',
    'month': 'Month',
    'last_month': 'Last_month',
    'year': 'Year',
    'language': 'Please select the language',
    'zh': ' Chinese',
    'en': 'English',
    'ma': 'Malay',
    'ge': 'German',
    'fr': 'French',
    'lt': 'Italian',
    'sp': 'Spanish',
    'tw': 'Zh-hant',
    'jp': 'Japanese',
    'po': 'Poland',
    'por': 'Portuguese',
    'download': 'Download Reports',
    'advise': 'Advice:Tire has uneven wear, Please do further check',


    'txt_tire_age_5': 'There are tires have been used for more than 5 years, it is recommended to conduct a comprehensive inspection of tires;',
    'txt_tire_age_3': 'There are tires that have been used for more than 3 years;',
    'txt_tire_age_ok': 'Good tire life;',
    'txt_tire_trauma_check': 'There is a wound on the tire surface, further examination is recommended;',
    'txt_install_abnormal_check': 'Tire installation is abnormal, further inspection is recommended;',
    'txt_tire_appearance_ok': 'Tires in good condition;',
    'txt_pinnate_check': 'There is feather wear on the tire. It is recommended to further check the chassis parts;',
    'trauma_lacerate': 'Lacerate',
    'trauma_dropblock': 'Drop block',
    'trauma_puncture': 'Puncture',
    'trauma_scratch': 'Scratch',
    'trauma_pinnate': 'Pinnate wear',
    'txt_tire_age': 'Service life of tires',
    'txt_tire appearance': 'The tire appearance',
    'tire_installed': 'Tire installed',
    'abnormal': 'Reverse inside and outside',
    'endurance': 'Estimation of tire range',
    'endurance-warn': 'The remaining range is {number}km',
    'endurance-zero': 'The tire has reached the limit, please replace it immediately',
    'estimated_value': 'Estimated value',
    'abrasion': 'Wear',
    'remanent': 'The remaining {days} days of tire use time',
    'useful_life': 'Estimation of tire service life',
    'dot_explain': 'DOT：tire manufacturing date',
    'unit_km': 'Units：km',
    'unit_year': 'Units：year',
    'dot_no': 'The correct production time was not obtained',
    'made_time': 'Production time: {dot} ({year} year {week} week)',
    'exceed_five': 'The service life of tires has exceeded 5 years, please replace them immediately',
    'inspector': 'Technician',
    'txt_install_check': 'Installation inspection',
    'txt_install_ok': 'Normal installation',
    'txt_pinnate_wear': 'Pinnate wear',


    'LFtxt_tire_need_replace': '[L-F]Tire wear status: Replace immediately',
    'RFtxt_tire_need_replace': '[R-F]Tire wear status: Replace immediately',
    'LBtxt_tire_need_replace': '[L-R]Tire wear status: Replace immediately',
    'RBtxt_tire_need_replace': '[R-R]Tire wear status: Replace immediately',
    'FFtxt_tire_need_replace': '[F-F]Tire wear status: Replace immediately',
    'BBtxt_tire_need_replace': '[B-B]Tire wear status: Replace immediately',


    'txt_pattern_wear': 'Uneven',
    'txt_uneven_center': 'Abnormal wear in the middle of the tire. It is recommended to check the tire pressure',
    'LFtxt_uneven_center': '[L-F]Abnormal wear in the middle of the tire. It is recommended to check the tire pressure',
    'RFtxt_uneven_center': '[R-F]Abnormal wear in the middle of the tire. It is recommended to check the tire pressure',
    'LBtxt_uneven_center': '[L-R]Abnormal wear in the middle of the tire. It is recommended to check the tire pressure',
    'RBtxt_uneven_center': '[R-R]Abnormal wear in the middle of the tire. It is recommended to check the tire pressure',
    'FFtxt_uneven_center': '[F-F]Abnormal wear in the middle of the tire. It is recommended to check the tire pressure',
    'BBtxt_uneven_center': '[B-B]Abnormal wear in the middle of the tire. It is recommended to check the tire pressure',

    'txt_uneven_out': 'The outer side of the tire is worn abnormally. It is recommended to check the four-wheel alignment and tire pressure',
    'LFtxt_uneven_out': '[L-F]The outer side of the tire is worn abnormally. It is recommended to check the four-wheel alignment and tire pressure',
    'RFtxt_uneven_out': '[R-F]The outer side of the tire is worn abnormally. It is recommended to check the four-wheel alignment and tire pressure',
    'LBtxt_uneven_out': '[L-R]The outer side of the tire is worn abnormally. It is recommended to check the four-wheel alignment and tire pressure',
    'RBtxt_uneven_out': '[R-R]The outer side of the tire is worn abnormally. It is recommended to check the four-wheel alignment and tire pressure',
    'FFtxt_uneven_out': '[F-F]The outer side of the tire is worn abnormally. It is recommended to check the four-wheel alignment and tire pressure',
    'BBtxt_uneven_out': '[B-B]The outer side of the tire is worn abnormally. It is recommended to check the four-wheel alignment and tire pressure',

    'LFtxt_pinnate_check': '[L-F]There is feather wear on the tire. It is recommended to further check the chassis parts;',
    'RFtxt_pinnate_check': '[R-F]There is feather wear on the tire. It is recommended to further check the chassis parts;',
    'LBtxt_pinnate_check': '[L-R]There is feather wear on the tire. It is recommended to further check the chassis parts;',
    'RBtxt_pinnate_check': '[R-R]There is feather wear on the tire. It is recommended to further check the chassis parts;',
    'FFtxt_pinnate_check': '[F-F]There is feather wear on the tire. It is recommended to further check the chassis parts;',
    'BBtxt_pinnate_check': '[B-B]There is feather wear on the tire. It is recommended to further check the chassis parts;',

    'txt_uneven_inside': 'The inner side of the tire is worn abnormally. It is recommended to check the four-wheel alignment and tire pressure',
    'LFtxt_uneven_inside': '[L-F]The inner side of the tire is worn abnormally. It is recommended to check the four-wheel alignment and tire pressure',
    'RFtxt_uneven_inside': '[R-F]The inner side of the tire is worn abnormally. It is recommended to check the four-wheel alignment and tire pressure',
    'LBtxt_uneven_inside': '[L-R]The inner side of the tire is worn abnormally. It is recommended to check the four-wheel alignment and tire pressure',
    'RBtxt_uneven_inside': '[R-R]The inner side of the tire is worn abnormally. It is recommended to check the four-wheel alignment and tire pressure',
    'FFtxt_uneven_inside': '[F-F]The inner side of the tire is worn abnormally. It is recommended to check the four-wheel alignment and tire pressure',
    'BBtxt_uneven_inside': '[B-B]The inner side of the tire is worn abnormally. It is recommended to check the four-wheel alignment and tire pressure',

    'FFtxt_uneven_check': '[F-F]Abnormal tire wear, it is recommended to check the four-wheel positioning and chassis parts',
    'BBtxt_uneven_check': '[B-B]Abnormal tire wear, it is recommended to check the four-wheel positioning and chassis parts',


    'LFtxt_tire_age_5': '[L-F]There are tires have been used for more than 5 years, it is recommended to conduct a comprehensive inspection of tires;',
    'RFtxt_tire_age_5': '[R-F]There are tires have been used for more than 5 years, it is recommended to conduct a comprehensive inspection of tires;',
    'LBtxt_tire_age_5': '[L-R]There are tires have been used for more than 5 years, it is recommended to conduct a comprehensive inspection of tires;',
    'RBtxt_tire_age_5': '[R-R]There are tires have been used for more than 5 years, it is recommended to conduct a comprehensive inspection of tires;',
    'FFtxt_tire_age_5': '[F-F]There are tires have been used for more than 5 years, it is recommended to conduct a comprehensive inspection of tires;',
    'BBtxt_tire_age_5': '[B-B]There are tires have been used for more than 5 years, it is recommended to conduct a comprehensive inspection of tires;',

    'txt_appearance_check': 'The appearance of the tire is abnormal, further inspection is recommended',
    'LFtxt_appearance_check': '[L-F]The appearance of the tire is abnormal, further inspection is recommended',
    'RFtxt_appearance_check': '[R-F]The appearance of the tire is abnormal, further inspection is recommended',
    'LBtxt_appearance_check': '[L-R]The appearance of the tire is abnormal, further inspection is recommended',
    'RBtxt_appearance_check': '[R-R]The appearance of the tire is abnormal, further inspection is recommended',
    'FFtxt_appearance_check': '[F-F]The appearance of the tire is abnormal, further inspection is recommended',
    'BBtxt_appearance_check': '[B-B]The appearance of the tire is abnormal, further inspection is recommended',


    'LFtxt_install_abnormal_check': '[L-F]The tire is installed backwards, please confirm to deal with it',
    'RFtxt_install_abnormal_check': '[R-F]The tire is installed backwards, please confirm to deal with it',
    'LBtxt_install_abnormal_check': '[L-R]The tire is installed backwards, please confirm to deal with it',
    'RBtxt_install_abnormal_check': '[R-R]The tire is installed backwards, please confirm to deal with it',
    'FFtxt_install_abnormal_check': '[F-F]The tire is installed backwards, please confirm to deal with it',
    'BBtxt_install_abnormal_check': '[B-B]The tire is installed backwards, please confirm to deal with it',

    'txt_uneven_bothside': 'Abnormal wear on both sides of the tire. It is recommended to check the four-wheel positioning and tire pressure.',
    'LFtxt_uneven_bothside': '[L-F]Abnormal wear on both sides of the tire. It is recommended to check the four-wheel positioning and tire pressure.',
    'RFtxt_uneven_bothside': '[R-F]Abnormal wear on both sides of the tire. It is recommended to check the four-wheel positioning and tire pressure.',
    'LBtxt_uneven_bothside': '[L-R]Abnormal wear on both sides of the tire. It is recommended to check the four-wheel positioning and tire pressure.',
    'RBtxt_uneven_bothside': '[R-R]Abnormal wear on both sides of the tire. It is recommended to check the four-wheel positioning and tire pressure.',
    'FFtxt_uneven_bothside': '[F-F]Abnormal wear on both sides of the tire. It is recommended to check the four-wheel positioning and tire pressure.',
    'BBtxt_uneven_bothside': '[B-B]Abnormal wear on both sides of the tire. It is recommended to check the four-wheel positioning and tire pressure.',

    'txt_wear_lookup': 'Please check tire wear',
    'LFtxt_wear_lookup': '[L-F]Please check tire wear',
    'RFtxt_wear_lookup': '[R-F]Please check tire wear',
    'LBtxt_wear_lookup': '[L-R]Please check tire wear',
    'RBtxt_wear_lookup': '[R-R]Please check tire wear',
    'FFtxt_wear_lookup': '[F-F]Please check tire wear',
    'BBtxt_wear_lookup': '[B-B]Please check tire wear',

    'txt_appearance_lookup': 'Please check the appearance of the tires',
    'LFtxt_appearance_lookup': '[L-F]Please check the appearance of the tires',
    'RFtxt_appearance_lookup': '[R-F]Please check the appearance of the tires',
    'LBtxt_appearance_lookup': '[L-R]Please check the appearance of the tires',
    'RBtxt_appearance_lookup': '[R-R]Please check the appearance of the tires',
    'FFtxt_appearance_lookup': '[F-F]Please check the appearance of the tires',
    'BBtxt_appearance_lookup': '[B-B]Please check the appearance of the tires',

    'txt_serious_trauma_check': 'The tire has serious injury. It is recommended to replace the tire',
    'LFtxt_serious_trauma_check': '[L-F]The tire has serious injury. It is recommended to replace the tire',
    'RFtxt_serious_trauma_check': '[R-F]The tire has serious injury. It is recommended to replace the tire',
    'LBtxt_serious_trauma_check': '[L-B]The tire has serious injury. It is recommended to replace the tire',
    'RBtxt_serious_trauma_check': '[R-B]The tire has serious injury. It is recommended to replace the tire',
    'FFtxt_serious_trauma_check': '[F-F]The tire has serious injury. It is recommended to replace the tire',
    'BBtxt_serious_trauma_check': '[B-B]The tire has serious injury. It is recommended to replace the tire',

    'purp_tireservice': 'Tire service',
    'purp_vehicle_inspection': 'Vehicle inspection',
    'store_user':'Login as a store user?',
    'distributor':'Login as a dealer user?',
    'merchant': 'Merchant',
    'manager': 'Manager',
    'manager_email': 'Manager Email',
    'assistant': 'Assistant',

    'txt_chassis_check': 'Chassis check',
    'txt_chassis_ok': 'The chassis is well checked',
    'LFtxt_chassis_ok': '[L-F]The chassis is well checked',
    'RFtxt_chassis_ok': '[R-F]The chassis is well checked',
    'LBtxt_chassis_ok': '[L-B]The chassis is well checked',
    'RBtxt_chassis_ok': '[R-R]The chassis is well checked',
    'FFtxt_chassis_ok': '[F-F]The chassis is well checked',
    'BBtxt_chassis_ok': '[B-B]The chassis is well checked',
    'txt_chassis_oil': 'The shock absorber leaks oil, further confirmation is recommended, if necessary, please replace it.',
    'LFtxt_chassis_oil': '[L-F]The shock absorber leaks oil, further confirmation is recommended, if necessary, please replace it.',
    'RFtxt_chassis_oil': '[R-F]The shock absorber leaks oil, further confirmation is recommended, if necessary, please replace it.',
    'LBtxt_chassis_oil': '[L-B]The shock absorber leaks oil, further confirmation is recommended, if necessary, please replace it.',
    'RBtxt_chassis_oil': '[R-R]The shock absorber leaks oil, further confirmation is recommended, if necessary, please replace it.',
    'FFtxt_chassis_oil': '[F-F]The shock absorber leaks oil, further confirmation is recommended, if necessary, please replace it.',
    'BBtxt_chassis_oil': '[B-B]The shock absorber leaks oil, further confirmation is recommended, if necessary, please replace it.',
    'txt_chassis_aging': 'Axle head rubber aging, replacement treatment is recommended.',
    'LFtxt_chassis_aging': '[L-F]Axle head rubber aging, replacement treatment is recommended.',
    'RFtxt_chassis_aging': '[R-F]Axle head rubber aging, replacement treatment is recommended.',
    'LBtxt_chassis_aging': '[L-B]Axle head rubber aging, replacement treatment is recommended.',
    'RBtxt_chassis_aging': '[R-R]Axle head rubber aging, replacement treatment is recommended.',
    'FFtxt_chassis_aging': '[F-F]Axle head rubber aging, replacement treatment is recommended.',
    'BBtxt_chassis_aging': '[B-B]Axle head rubber aging, replacement treatment is recommended.',
    'awd': 'App Download',

    'depth': 'Tread Depth',
    'usura': 'Excessive Wear',
    'usura-l': 'Normal',
    'usura-m': 'Slight Wear',
    'usura-h': 'Severe Wear',

    'engine-hood': 'ENGINE BONNET',
    'change-control': 'OTHER CHECKS',
    'subtotal': 'UNDERBODY',

    'replace': 'REPLACE',
    'vedi': 'READ NOTE',
    'ok': 'OK',

    'liquid': 'GLASS WASHING LIQUID',
    'level-control': 'Level control',
    'air': 'AIR CONDITIONER',
    'last-reload': 'Last recharge',
    'absorber': 'SHOCK ABSORBER',
    'check-loss': 'Check of visible leaks',
    'fluid': 'RADIATOR FLUID',
    'lighting': 'LIGHTS',
    'verification': 'Opeation check',
    'disc': 'BRAKE DISCS',
    'condition': 'Wearing',
    'oil': 'MOTOR OIL',
    'wiper': 'WIPERS',
    'pads': 'BRAKE PADS',
    'brake-fluid': 'BRAKE FLUID',
    'quality-control': 'Quality check',
    'battery': 'Battery',
    'multimetro': 'Multimeter test',
    'control-quality': 'QUALITY CHECK',
    'tightening': 'Tightening with dynamometer made by',
    'buone': 'OK',
    'lavoro': 'Work Scheduling',
    'concordare': 'Allow Substitution',
    'season_summer': 'Summer tire',
    'season_winter': 'Winter tire',
    'wearecc': 'Uneven',

    'inspection_list': 'Vehicle Inspection List',
    'lights': 'Lights',
    'high_beam': 'High Beam',
    'low_beam': 'Low Beam',
    'daytime': 'Daytime',
    'fog_light': 'Fog Light',
    'break_light': 'Brake Light',
    'turn_signals': 'Turn Signals',
    'backup_lights': 'Backup Lights',
    'hazards': 'Hazards',
    'glass': 'Glass',
    'windshield': 'Windshield',
    'backglass': 'Back Glass',
    'right_glass': 'Right Side Windows',
    'left_glass': 'Left Side Windows',
    'wipers_front': 'Wipers Front',
    'wipers_rear': 'Wipers Rear',
    'under_hood': 'Under Hood',
    'horn': 'Horn',
    'oil_level': 'Oil Level',
    'air_filter': 'Air Filter',
    'trans_fluid': 'Trans Fluid',
    'break_fluid': 'Brake Fluid',
    'coolant_level': 'Coolant Level',
    'windshield_washer_fluid': 'Windshield Washer Fluid',
    'power_steering_fluid': 'Power Steering Fluid',
    'belts': 'Belts',
    'hoses': 'Hoses',
    'ac_system': 'AC System',
    // 'battery':'蓄电池',
    'under_vehicle': 'Under Vehicle',
    'suspension': 'Suspension',
    'exhaust': 'Exhaust',
    'break_lines': 'Brake Lines',
    'oil_drain_plug': 'Oil Drain Plug',
    'differential': 'Differential',
    'breaks': 'Brakes',
    'le_fr_brake': 'Front Left',
    'ri_fr_brake': 'Front Right',
    'le_re_brake': 'Rear Left',
    'ri_re_brake': 'Rear Right',
    'parking_brake': 'Parking Brake',
    'interior': 'Interior',
    'cabin_filter': 'Cabin Filter',
    'heating': 'Heating ',
    'interior_lights': 'Interior Lights',
    'fr_defrost': 'Front Defrost',
    're_defrost': 'Rear Defrost',
    'pressure': 'Pressure',
    'nor':'OK',
    'caution':'Caution',
    'attention':'Needs Attention',

    'com': 'Commercial',
    'pass': 'Passenger',
    'reports': 'Inspection Reports',
    'devices': 'Device Management',
    'setting': 'System Setting',
    'confirm': 'Confirm',
    'cancel' : 'Cancel',
    'bind_btn': 'Bind',

    // 大车翻译
    'truck': 'Tractor',
    'mount': 'Trailer',
    'left': 'Left',
    'right': 'Right',
    'undevice': 'Not scan',
    'axle': 'Axle',
    'spare': 'Spare Tire',
    'nodata': 'NO Data',

    'dashboard': 'Dashboard',

}
